'use strict';

angular.module('windmanagerApp')
  .directive('showAll', function() {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        $(element).openClose(scope.$eval(attrs.showAll));
      }
    };
  });
